import React from 'react'
import AudienceView from './AudienceView'
import smImage from '../../assets/images/hero-customer-enablement-sm.png'
import mdImage from '../../assets/images/hero-customer-enablement-md.png'
import lgImage from '../../assets/images/hero-customer-enablement.png'

const salesItems = [
  { text: 'key-benefits:customer-enablement:item1' },
  { text: 'key-benefits:customer-enablement:item2' },
  { text: 'key-benefits:customer-enablement:item3' },
  { text: 'key-benefits:customer-enablement:item4' },
  { text: 'key-benefits:customer-enablement:item5' },
]

const CustomerEnablementView = () => {
  return (
    <>
      <AudienceView
        page="customer-enablement"
        title="use-case:customer-enablement:title"
        subtitle="noun:customer-enablement"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        videoTitle="Aucta customer-enablement video"
        videoUrl="https://www.youtube.com/embed/tbQz3h3MlWo"
        items={salesItems}
        buttonTo="/ceramtec"
      />
    </>
  )
}

export default CustomerEnablementView
