import React from 'react'
import MainLayout from '../layouts/MainLayout'
import CustomerEnablementView from '../views/CustomerEnablementView'

const CustomerEnablementPage = () => {
  return (
    <MainLayout className="customer-enablement">
      <CustomerEnablementView />
    </MainLayout>
  )
}

export default CustomerEnablementPage
