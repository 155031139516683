import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'
import CustomerEnablementPage from '../ui/pages/CustomerEnablementPage'

const CustomerEnablement = props => (
  <>
    <HelmetComponent
      title={metaTags.customerEnablementTitle}
      description={metaTags.CustomerEnablement}
      page="de/customer-enablement"
    />
    <AppWrapper {...props} lang="de">
      <CustomerEnablementPage />
    </AppWrapper>
  </>
)

export default CustomerEnablement
